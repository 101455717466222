import React from "react"

import SEO from "../../components/seo"
import Page from "../../components/layout/Page"

const ReviewsPage = () => {
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (window.Trustpilot) {
        window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <>
      <SEO page="reviews" title="SignMonkey Customer Reviews" />
      <Page breadcrumb={[
        { url: "/", title: "Home"},
        { url: "/why/", title: "Why SignMonkey"},
        { url: null, title: "Customer Reviews" }
      ]}>
        <div
            ref={ref} 
            className="trustpilot-widget"
            data-locale="en-US" 
            data-template-id="539adbd6dec7e10e686debee" 
            data-businessunit-id="5a31a4d4b894c90d84225a75" 
            data-style-height="1200px" 
            data-style-width="100%" 
            data-theme="light"
            data-stars="1,2,3,4,5"
        >
            <a href="https://www.trustpilot.com/review/signmonkey.com" target="_blank" rel="noopener noreferrer">
                Trustpilot
            </a> 
        </div>
      </Page>
    </>
  )
}

export default ReviewsPage